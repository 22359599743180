import { ApolloClient } from "@apollo/client/main.cjs";
import {
  mutationSellingPlanGroupAddProductVariants,
  mutationSellingPlanGroupAddProducts,
  mutationSellingPlanGroupRemoveProductVariants,
  mutationSellingPlanGroupRemoveProducts,
} from "@smartrr/shared/shopifyGraphQL/sellingPlans";

import { typedFrontendVendorApi } from "./typedFrontendVendorApi";

const MAX_PAGE_SIZE = 250;

export async function addProductsToGroup(
  client: ApolloClient<object>,
  groupId: string,
  productIds: string[]
): Promise<void> {
  if (productIds.length > 250) {
    for (let i = 0; i < productIds.length / MAX_PAGE_SIZE; i++) {
      await mutationSellingPlanGroupAddProducts(
        productIds.slice(i * MAX_PAGE_SIZE, i * MAX_PAGE_SIZE + 250),
        groupId,
        client
      );
    }
    return;
  }
  await mutationSellingPlanGroupAddProducts(productIds, groupId, client);
}

export async function addVariantsToGroup(
  client: ApolloClient<object>,
  groupId: string,
  productVariantIds: string[]
) {
  if (productVariantIds.length > 250) {
    for (let i = 0; i < productVariantIds.length / MAX_PAGE_SIZE; i++) {
      await mutationSellingPlanGroupAddProductVariants(
        productVariantIds.slice(i * MAX_PAGE_SIZE, i * MAX_PAGE_SIZE + 250),
        groupId,
        client
      );
    }
    return;
  }
  await mutationSellingPlanGroupAddProductVariants(productVariantIds, groupId, client);
}

export async function removeProductsFromGroup(
  client: ApolloClient<object>,
  groupId: string,
  productIds: string[]
): Promise<void> {
  if (productIds.length > 250) {
    for (let i = 0; i < productIds.length / MAX_PAGE_SIZE; i++) {
      await mutationSellingPlanGroupRemoveProducts(
        productIds.slice(i * MAX_PAGE_SIZE, i * MAX_PAGE_SIZE + 250),
        groupId,
        client
      );
    }
    return;
  }
  await mutationSellingPlanGroupRemoveProducts(productIds, groupId, client);
}

export async function removeVariantsFromGroup(
  client: ApolloClient<object>,
  groupId: string,
  productVariantIds: string[]
) {
  if (productVariantIds.length > 250) {
    for (let i = 0; i < productVariantIds.length / MAX_PAGE_SIZE; i++) {
      await mutationSellingPlanGroupRemoveProductVariants(
        productVariantIds.slice(i * MAX_PAGE_SIZE, i * MAX_PAGE_SIZE + 250),
        groupId,
        client
      );
    }
    return;
  }
  await mutationSellingPlanGroupRemoveProductVariants(productVariantIds, groupId, client);
}

export const syncSellingPlanGroup = async (
  addToast: (text: string) => void,
  groupId?: string | null,
  options?: any
) => {
  if (!groupId) {
    addToast("Database sync failed");
    return;
  }

  let numericGroupId;

  if (typeof groupId !== "number") {
    numericGroupId = groupId.replaceAll(/^\D+/g, "");
  }

  return typedFrontendVendorApi.putReq(`/selling-plan-group/${numericGroupId}/sync`, options && { ...options });
};

export const syncSellingPlanGroups = async () => {
  return typedFrontendVendorApi.putReq("/selling-plan-group/sync");
};

export function bustSellingPlanGroupCache() {
  return typedFrontendVendorApi.getReq("/selling-plan-group", {
    query: {
      cache: "false",
    },
  });
}

export function bustAddOnsCache() {
  return typedFrontendVendorApi.getReq("/add-ons-config", {
    query: {
      cache: "false",
    },
  });
}

export function bustTrendingListsCache() {
  return typedFrontendVendorApi.getReq("/trending-lists-config", {
    query: {
      cache: "false",
    },
  });
}
