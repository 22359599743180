import { ApolloClient, useApolloClient } from "@apollo/client/main.cjs";
import { Stack } from "@mui/material";
import { Banner, LegacyCard, Page, TextField } from "@shopify/polaris";
import { RefreshMajor } from "@shopify/polaris-icons";
import { IS_PROD } from "@smartrr/shared/constants";
import { Box } from "@smartrr/shared/components/primitives";
import { Req } from "@smartrr/shared/req";
import { queryShopifyScriptTags } from "@smartrr/shared/shopifyGraphQL/scriptTag";
import { getShopifyThemes } from "@smartrr/shared/shopifyRest/theme";
import { ShopLink } from "@vendor-app/app/_sharedComponents/ShopLink";
import React, { useCallback, useState } from "react";
import styled from "styled-components";

import { useToast } from "@vendor-app/app/_sharedComponents/Toast/ToastProvider";
import { useSmartrrVendorSelector } from "@vendor-app/app/_state/typedVendorReduxHooks";

import StripeConnect from "./stripe";
import { StreamShopifyEntitiesSelect } from "./StreamShopifyEntitiesButton";
import { SyncOneContractButton } from "./SyncOneContractButton";
import { WebhookButton } from "./WebhookButton";
import { useRestClient } from "../../components/auth/RestProviderWrapper";

const SyncPageBox = styled(Box)`
  & strong {
    color: inherit;
    font-weight: 600;
  }

  & .Polaris-Page-Header,
  & .Polaris-Page {
    padding: 0;

    & h1 {
      margin-top: 2rem;
      margin-bottom: 20px;
    }
  }

  & .Polaris-Layout__Section {
    padding-top: 2em;

    & .subheading {
      margin-top: 20px;
    }
  }

  & .Polaris-Icon--colorInkLighter::before {
    background-color: transparent !important;
  }
`;
const FooterButton = styled.div`
  display: flex;
  justify-content: end;
  width: 100%;
`;
const SelectWrapper = styled.div`
  margin-top: 20px;
`;

export function SyncActions(): React.JSX.Element {
  const { addToast } = useToast();
  const apolloClient = useApolloClient();
  const restClient = useRestClient();
  const isSuperUser = useSmartrrVendorSelector(state => !!state.auth.user?.isSuperUser);
  const activeOrganizationId = useSmartrrVendorSelector(state => state.vendorOrganizations.activeOrganizationId);

  const webhooksActivationToast = useCallback(
    (isActivated: boolean) => addToast(`Webhooks ${isActivated ? "Activated" : "Deactivated"}`),
    []
  );

  const [syncValue, setSyncValue] = useState("");

  return (
    <SyncPageBox>
      <Page title="Sync actions">
        <Banner icon={RefreshMajor}>
          <p>As necessary, use manual override buttons here to force sync Smartrr with Shopify data.</p>
        </Banner>
        <div style={{ marginTop: "20px" }} />
        <LegacyCard sectioned title="Resync Shopify entities">
          <LegacyCard.Section title="Bulk sync">
            <Banner status="warning">
              <p>
                Before using these force actions, we recommend first{" "}
                <ShopLink to={"/admin/support"}>contacting support</ShopLink>. In the event some Shopify entities
                are still missing from Smartrr, you can then use one of the below buttons to force reload data
                from Shopify.
              </p>
              <p>
                <strong>Do not close or reload your browser tab while force sync is in progress.</strong>
              </p>
            </Banner>
            <SelectWrapper>
              <StreamShopifyEntitiesSelect />
            </SelectWrapper>
          </LegacyCard.Section>
        </LegacyCard>
        <LegacyCard title="Resync contract">
          <Stack spacing={"20px"} sx={{ padding: "0px 20px 20px 20px" }}>
            <span style={{ color: "var(--p-color-text-subdued)" }}>
              Use to force sync an individual missing contract.
            </span>
            <TextField
              label="Contract ID"
              type="text"
              helpText=""
              value={syncValue}
              onChange={e => setSyncValue(e)}
              autoComplete="off"
            />
            <FooterButton>
              <SyncOneContractButton contractId={syncValue} />
            </FooterButton>
          </Stack>
        </LegacyCard>
        {
          // not using at all right now, may need to for automated Recharge import
          !IS_PROD() || isSuperUser ? (
            <LegacyCard title="Stripe actions">
              <div style={{ padding: "0px 20px 20px 20px" }}>
                <span style={{ color: "var(--p-color-text-subdued)" }}>
                  If you have legacy subscriptions with another subscription app through Stripe, connect your
                  Stripe account here and contact <a href="mailto:hello@smartrr.com">hello@smartrr.com</a> to
                  migrate.
                </span>
                <div>
                  <FooterButton>
                    <StripeConnect />
                  </FooterButton>
                </div>
              </div>
            </LegacyCard>
          ) : null
        }
        {isSuperUser ? (
          <LegacyCard sectioned title="Get Actions">
            <FooterButton>
              <div className="Polaris-ButtonGroup">
                <div className="Polaris-ButtonGroup__Item">
                  <button
                    className="Polaris-Button"
                    type="button"
                    onClick={activeOrganizationId ? () => getScriptTags(apolloClient) : undefined}
                  >
                    <span className="Polaris-Button__Content">
                      <span className="Polaris-Button__Text">Get all tags</span>
                    </span>
                  </button>
                </div>
                <div className="Polaris-ButtonGroup__Item">
                  <button
                    className="Polaris-Button"
                    type="button"
                    onClick={activeOrganizationId ? () => getThemes(restClient) : undefined}
                  >
                    <span className="Polaris-Button__Content">
                      <span className="Polaris-Button__Text">Get all themes</span>
                    </span>
                  </button>
                </div>
              </div>
            </FooterButton>
          </LegacyCard>
        ) : null}
        {!IS_PROD() || isSuperUser ? (
          <LegacyCard sectioned title="Webhooks">
            {!!activeOrganizationId && (
              <FooterButton>
                <WebhookButton onWebhooksStateChange={webhooksActivationToast} />
              </FooterButton>
            )}
          </LegacyCard>
        ) : null}
      </Page>
    </SyncPageBox>
  );
}

async function getScriptTags(client: ApolloClient<object>) {
  const res = await queryShopifyScriptTags(client, 1);
  if (res.type === "success") {
    // eslint-disable-next-line no-console
    console.log(res.body.data.scriptTags);
  }
}

async function getThemes(restClient: Req) {
  const res = await getShopifyThemes(restClient);
  if (res.type === "success") {
    // eslint-disable-next-line no-console
    console.log(res.body.themes);
  }
}
