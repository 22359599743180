import { ApolloClient } from "@apollo/client/main.cjs";

import {
  GetScriptTags,
  GetScriptTagsQuery,
  GetScriptTagsQueryVariables,
  ScriptTagCreate,
  ScriptTagCreateMutation,
  ScriptTagCreateMutationVariables,
  ScriptTagDelete,
  ScriptTagDeleteMutation,
  ScriptTagDeleteMutationVariables,
  ScriptTagInput,
  ScriptTagUpdate,
  ScriptTagUpdateMutation,
  ScriptTagUpdateMutationVariables,
} from "./api";

import { apolloShopifyMutation, apolloShopifyQuery } from ".";

export function queryShopifyScriptTags(client: ApolloClient<object>, first = 1, src?: string) {
  return apolloShopifyQuery<GetScriptTagsQuery, GetScriptTagsQueryVariables>(
    {
      query: GetScriptTags,
      variables: { first, src },
      fetchPolicy: "no-cache",
    },
    client
  );
}

export function mutationShopifyCreateScriptTag(input: ScriptTagInput, client: ApolloClient<object>) {
  return apolloShopifyMutation<ScriptTagCreateMutation, ScriptTagCreateMutationVariables>(
    {
      mutation: ScriptTagCreate,
      variables: { input },
    },
    client
  );
}

export function mutationShopifyUpdateScriptTag(id: string, input: ScriptTagInput, client: ApolloClient<object>) {
  return apolloShopifyMutation<ScriptTagUpdateMutation, ScriptTagUpdateMutationVariables>(
    {
      mutation: ScriptTagUpdate,
      variables: {
        id,
        input,
      },
    },
    client
  );
}

export function mutationShopifyDeleteScriptTag(id: string, client: ApolloClient<object>) {
  return apolloShopifyMutation<ScriptTagDeleteMutation, ScriptTagDeleteMutationVariables>(
    {
      mutation: ScriptTagDelete,
      variables: { id },
    },
    client
  );
}
